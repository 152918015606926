define('ember-paper-tabs/components/paper-tab/body', ['exports', 'ember-component', 'ember-computed', 'ember-paper-tabs/templates/components/paper-tab/body'], function (exports, _emberComponent, _emberComputed, _emberPaperTabsTemplatesComponentsPaperTabBody) {

  /**
   * @class PaperTabBody
   * @extends Component
   * @public
   */
  exports['default'] = _emberComponent['default'].extend({

    tagName: '',

    layout: _emberPaperTabsTemplatesComponentsPaperTabBody['default'],

    wormhole: _emberComputed['default'].readOnly('parentComponent.wormhole')

  });
});
/**
 * @module ember-paper-tabs
 * @public
 */