define('ember-paper-tabs/components/paper-tab/label', ['exports', 'ember-component', 'ember-paper-tabs/templates/components/paper-tab/label'], function (exports, _emberComponent, _emberPaperTabsTemplatesComponentsPaperTabLabel) {

  /**
   * @class PaperTabLabel
   * @extends Component
   * @public
   */
  exports['default'] = _emberComponent['default'].extend({

    tagName: 'span',

    layout: _emberPaperTabsTemplatesComponentsPaperTabLabel['default']

  });
});
/**
 * @module ember-paper-tabs
 * @public
 */