define('ember-paper-tabs/components/paper-tabs/canvas', ['exports', 'ember-component', 'ember-computed', 'ember-paper-tabs/templates/components/paper-tabs/canvas'], function (exports, _emberComponent, _emberComputed, _emberPaperTabsTemplatesComponentsPaperTabsCanvas) {

  /**
   * @class PaperTabsCanvas
   * @extends Component
   * @public
   */
  exports['default'] = _emberComponent['default'].extend({

    tagName: 'md-tabs-canvas',

    layout: _emberPaperTabsTemplatesComponentsPaperTabsCanvas['default'],

    /* Inherited from {{paper-tabs/wrapper}} */
    shouldPaginate: _emberComputed['default'].readOnly('parentComponent.shouldPaginate'),
    shouldCenterTabs: _emberComputed['default'].readOnly('parentComponent.shouldCenterTabs'),

    classNameBindings: ['shouldPaginate:md-paginated', 'shouldCenterTabs:md-center-tabs']

  });
});
/**
 * @module ember-paper-tabs
 * @public
 */