define('ember-paper-tabs/components/paper-tab', ['exports', 'ember-component', 'ember-computed', 'ember-metal/observer', 'ember-service/inject', 'ember-paper/mixins/ripple-mixin', 'ember-paper/mixins/color-mixin', 'ember-composability-tools', 'ember-paper-tabs/templates/components/paper-tab'], function (exports, _emberComponent, _emberComputed, _emberMetalObserver, _emberServiceInject, _emberPaperMixinsRippleMixin, _emberPaperMixinsColorMixin, _emberComposabilityTools, _emberPaperTabsTemplatesComponentsPaperTab) {

  /**
   * @class PaperTab
   * @extends Component
   * @uses RippleMixin
   * @uses ColorMixin
   * @uses ChildMixin
   * @public
   */
  exports['default'] = _emberComponent['default'].extend(_emberPaperMixinsRippleMixin['default'], _emberPaperMixinsColorMixin['default'], _emberComposabilityTools.ChildMixin, {

    tagName: 'md-tab-item',

    layout: _emberPaperTabsTemplatesComponentsPaperTab['default'],

    constants: (0, _emberServiceInject['default'])(),

    classNames: ['md-tab'],

    classNameBindings: ['isActive:md-active', 'disabled:md-disabled'],

    /* Inherited from `{{paper-tabs}}` */
    selected: _emberComputed['default'].readOnly('parentComponent.selected'),
    previous: _emberComputed['default'].readOnly('parentComponent.previous'),
    tabs: _emberComputed['default'].readOnly('parentComponent.tabs'),
    wormhole: _emberComputed['default'].readOnly('parentComponent.wormhole'),
    noink: _emberComputed['default'].readOnly('parentComponent.noInk'),

    rippleContainerSelector: null,

    index: (0, _emberComputed['default'])('tabs.[]', function () {
      return this.get('tabs') ? this.get('tabs').indexOf(this) : -1;
    }),

    isActive: (0, _emberComputed['default'])('index', 'selected', function () {
      return this.get('index') !== -1 && this.get('index') === this.get('selected');
    }),

    isLeft: (0, _emberComputed['default'])('selected', 'index', function () {
      return this.get('index') !== -1 && this.get('index') < this.get('selected');
    }),

    isRight: (0, _emberComputed['default'])('selected', 'index', function () {
      return this.get('index') !== -1 && this.get('index') > this.get('selected');
    }),

    // TODO would it be cleaner a tryInvoke(tab, 'onDeselect') by paper-tabs ?
    didDeselect: (0, _emberMetalObserver['default'])('isActive', function () {
      if (this.get('onDeselect') && this.get('index') !== -1 && this.get('index') === this.get('previous') && !this.get('isActive')) {
        return this.get('onDeselect')();
      }
    }),

    selectTab: function selectTab() {
      // TODO would it be cleaner a tryInvoke(tab, 'onSelect') by paper-tabs ?
      if (!this.get('disabled')) {
        if (this.get('onSelect')) {
          this.get('onSelect')();
        }
        this.get('parentComponent').send('selectTab', this);
      }
    },

    /* Events */

    keyDown: function keyDown(ev) {
      if (ev.which === this.get('constants.KEYCODE.ENTER') || ev.which === this.get('constants.KEYCODE.SPACE')) {
        this.selectTab();
      }
    },

    click: function click() {
      this.selectTab();
    }
  });
});
/**
 * @module ember-paper-tabs
 * @public
 */