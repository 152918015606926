define('ember-paper-tabs/components/paper-tab/content', ['exports', 'ember-component', 'ember-computed', 'ember-runloop', 'ember-paper-tabs/templates/components/paper-tab/content'], function (exports, _emberComponent, _emberComputed, _emberRunloop, _emberPaperTabsTemplatesComponentsPaperTabContent) {

  /**
   * @class PaperTabContent
   * @extends Component
   * @public
   */
  exports['default'] = _emberComponent['default'].extend({

    tagName: 'md-tab-content',

    layout: _emberPaperTabsTemplatesComponentsPaperTabContent['default'],

    classNameBindings: ['isActive:md-active', 'isLeft:md-left', 'isRight:md-right'],

    /* Inherited from `{{paper-tab}}` */
    isActive: _emberComputed['default'].readOnly('parentComponent.isActive'),
    isLeft: _emberComputed['default'].readOnly('parentComponent.isLeft'),
    isRight: _emberComputed['default'].readOnly('parentComponent.isRight'),

    // necessary for paper-tabs to know when the tab's content is actually rendered
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _emberRunloop['default'].schedule('afterRender', this, function () {
        this.get('parentComponent').trigger('onRendered');
      });
    }

  });
});
/**
 * @module ember-paper-tabs
 * @public
 */