define('ember-paper-tabs/components/paper-tabs/wrapper', ['exports', 'ember-component', 'ember-computed', 'ember-composability-tools', 'ember-paper-tabs/templates/components/paper-tabs/wrapper'], function (exports, _emberComponent, _emberComputed, _emberComposabilityTools, _emberPaperTabsTemplatesComponentsPaperTabsWrapper) {

  /**
   * @class PaperTabsWrapper
   * @extends Component
   * @uses ChildMixin
   * @public
   */
  exports['default'] = _emberComponent['default'].extend(_emberComposabilityTools.ChildMixin, {

    tagName: 'md-tabs-wrapper',

    layout: _emberPaperTabsTemplatesComponentsPaperTabsWrapper['default'],

    classNameBindings: ['shouldStretchTabs:md-stretch-tabs'],

    /* Inherited from {{paper-tabs}} */
    shouldStretchTabs: _emberComputed['default'].readOnly('parentComponent.shouldStretchTabs'),
    shouldPaginate: _emberComputed['default'].readOnly('parentComponent.shouldPaginate'),
    shouldCenterTabs: _emberComputed['default'].readOnly('parentComponent.shouldCenterTabs'),
    canPageBack: _emberComputed['default'].readOnly('parentComponent.canPageBack'),
    canPageForward: _emberComputed['default'].readOnly('parentComponent.canPageForward'),

    actions: {
      nextPage: function nextPage() {
        if (this.get('canPageForward')) {
          this.get('parentComponent').send('nextPage');
        }
      },
      previousPage: function previousPage() {
        if (this.get('canPageBack')) {
          this.get('parentComponent').send('previousPage');
        }
      }
    }
  });
});
/**
 * @module ember-paper-tabs
 * @public
 */