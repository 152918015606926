define('ember-paper-tabs/components/paper-prev-button', ['exports', 'ember-component', 'ember-paper-tabs/templates/components/paper-prev-button'], function (exports, _emberComponent, _emberPaperTabsTemplatesComponentsPaperPrevButton) {

  /**
   * @class PaperPrevButton
   * @extends Component
   * @public
   */
  exports['default'] = _emberComponent['default'].extend({

    tagName: 'md-prev-button',

    layout: _emberPaperTabsTemplatesComponentsPaperPrevButton['default'],

    classNameBindings: ['disabled:md-disabled']

  });
});
/**
 * @module ember-paper-tabs
 * @public
 */