define('emberfire-utils/utils/has-filtered', ['exports', 'ember-platform', 'ember-computed'], function (exports, _emberPlatform, _emberComputed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = hasFiltered;
  var ArrayProxy = Ember.ArrayProxy,
      PromiseProxyMixin = Ember.PromiseProxyMixin;


  /**
   * @param {string} modelName
   * @param {Object} [rawQuery={}]
   * @return {Utility.PromiseArray} Promise array resolving to records
   */
  function hasFiltered(modelName) {
    var rawQuery = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return (0, _emberComputed.default)({
      get: function get() {
        var PromiseArray = ArrayProxy.extend(PromiseProxyMixin);
        var query = (0, _emberPlatform.assign)({}, rawQuery);

        if (query.hasOwnProperty('cacheId')) {
          query.cacheId = query.cacheId.replace('$id', this.get('id'));
        }

        if (query.hasOwnProperty('path')) {
          query.path = query.path.replace('$id', this.get('id'));
          query.path = query.path.replace('$innerReferencePath', this.get('_innerReferencePath'));
        }

        return PromiseArray.create({
          promise: this.get('store').query(modelName, query)
        });
      }
    }).readOnly();
  }
});