define('ember-paper-tabs/components/paper-next-button', ['exports', 'ember-component', 'ember-paper-tabs/templates/components/paper-next-button'], function (exports, _emberComponent, _emberPaperTabsTemplatesComponentsPaperNextButton) {

  /**
   * @class PaperNextButton
   * @extends Component
   * @public
   */
  exports['default'] = _emberComponent['default'].extend({

    tagName: 'md-next-button',

    layout: _emberPaperTabsTemplatesComponentsPaperNextButton['default'],

    classNameBindings: ['disabled:md-disabled']

  });
});
/**
 * @module ember-paper-tabs
 * @public
 */