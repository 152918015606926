define('ember-paper-tabs/components/paper-ink-bar', ['exports', 'ember-component', 'ember-computed', 'ember-string', 'ember-paper-tabs/templates/components/paper-ink-bar'], function (exports, _emberComponent, _emberComputed, _emberString, _emberPaperTabsTemplatesComponentsPaperInkBar) {

  /**
   * @class PaperInkBar
   * @extends Component
   * @public
   */
  exports['default'] = _emberComponent['default'].extend({

    tagName: 'md-ink-bar',

    layout: _emberPaperTabsTemplatesComponentsPaperInkBar['default'],

    classNameBindings: ['barClass'],

    attributeBindings: ['barStyle:style'],

    barClass: (0, _emberComputed['default'])('direction', function () {
      var direction = this.get('direction');
      if (direction) {
        return 'md-' + direction;
      }
    }),

    barStyle: (0, _emberComputed['default'])('leftPosition', 'rightPosition', function () {
      var left = parseInt(this.get('leftPosition'));
      var right = parseInt(this.get('rightPosition'));
      return (0, _emberString.htmlSafe)('left:' + left + 'px;right:' + right + 'px;');
    })

  });
});
/**
 * @module ember-paper-tabs
 * @public
 */