define('ember-paper-tabs/components/paper-pagination-wrapper', ['exports', 'ember-component', 'ember-computed', 'ember-string', 'ember-paper-tabs/templates/components/paper-pagination-wrapper'], function (exports, _emberComponent, _emberComputed, _emberString, _emberPaperTabsTemplatesComponentsPaperPaginationWrapper) {

  /**
   * @class PaperPaginationWrapper
   * @extends Component
   * @public
   */
  exports['default'] = _emberComponent['default'].extend({

    tagName: 'md-pagination-wrapper',

    layout: _emberPaperTabsTemplatesComponentsPaperPaginationWrapper['default'],

    classNameBindings: ['shouldCenterTabs:md-center-tabs'],

    attributeBindings: ['styleAttr:style'],

    /* Inherited from {{paper-tabs}} */
    tabs: _emberComputed['default'].readOnly('parentComponent.tabs'),
    noInkBar: _emberComputed['default'].readOnly('parentComponent.noInkBar'),
    lastSelectedIndex: _emberComputed['default'].readOnly('parentComponent.lastSelectedIndex'),
    selected: _emberComputed['default'].readOnly('parentComponent.selected'),
    selectedTab: _emberComputed['default'].readOnly('parentComponent.selectedTab'),
    canvasWidth: _emberComputed['default'].readOnly('parentComponent.canvasWidth'),
    pagingWidth: _emberComputed['default'].readOnly('parentComponent.pagingWidth'),
    offsetLeft: _emberComputed['default'].readOnly('parentComponent.offsetLeft'),
    shouldCenterTabs: _emberComputed['default'].readOnly('parentComponent.shouldCenterTabs'),
    shouldStretchTabs: _emberComputed['default'].readOnly('parentComponent.shouldStretchTabs'),
    shouldPaginate: _emberComputed['default'].readOnly('parentComponent.shouldPaginate'),
    selectedTabOffsetLeft: _emberComputed['default'].readOnly('parentComponent.selectedTabOffsetLeft'),
    selectedTabWidth: _emberComputed['default'].readOnly('parentComponent.selectedTabWidth'),

    noTabSelected: _emberComputed['default'].empty('selectedTab'),

    hideInkBar: _emberComputed['default'].or('noTabSelected', 'noInkBar'),

    styleAttr: (0, _emberComputed['default'])('shouldPaginate', 'offsetLeft', function () {
      if (this.get('shouldPaginate')) {
        return (0, _emberString.htmlSafe)('transform: translate3d(-' + this.get('offsetLeft') + 'px, 0px, 0px);');
      }
    }),

    inkBarDirection: (0, _emberComputed['default'])('lastSelectedIndex', 'selected', function () {
      return this.get('lastSelectedIndex') > this.get('selected') ? 'left' : 'right';
    }),

    inkBarLeftPosition: _emberComputed['default'].readOnly('selectedTabOffsetLeft'),

    inkBarRightPosition: (0, _emberComputed['default'])('pagingWidth', 'inkBarLeftPosition', 'selectedTabWidth', function () {
      return this.get('pagingWidth') - this.get('inkBarLeftPosition') - this.get('selectedTabWidth');
    })
  });
});
/**
 * @module ember-paper-tabs
 * @public
 */