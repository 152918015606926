define('ember-paper-tabs/components/paper-tabs', ['exports', 'ember-component', 'ember-computed', 'ember-runloop', 'ember-service/inject', 'ember-metal/observer', 'ember-array/utils', 'ember-string', 'ember-paper-tabs/templates/components/paper-tabs', 'ember-paper/mixins/color-mixin', 'ember-composability-tools', 'jquery'], function (exports, _emberComponent, _emberComputed, _emberRunloop, _emberServiceInject, _emberMetalObserver, _emberArrayUtils, _emberString, _emberPaperTabsTemplatesComponentsPaperTabs, _emberPaperMixinsColorMixin, _emberComposabilityTools, _jquery) {

  var offsetRight = function offsetRight($dom) {
    return $dom.prop('offsetLeft') + $dom.prop('clientWidth');
  };

  /**
   * @class PaperTabs
   * @extends Component
   * @uses ColorMixin
   * @uses ParentMixin
   * @public
   */
  exports['default'] = _emberComponent['default'].extend(_emberPaperMixinsColorMixin['default'], _emberComposabilityTools.ParentMixin, {

    tagName: 'md-tabs',

    layout: _emberPaperTabsTemplatesComponentsPaperTabs['default'],

    constants: (0, _emberServiceInject['default'])(),

    /* Settings */
    dynamicHeight: false,
    alignTabs: 'top',
    noInk: false,
    noInkBar: false,
    centerTabs: false,
    stretchTabs: 'auto',
    autoSelect: false,
    borderBottom: false,
    theme: 'default',

    classNameBindings: ['dynamicHeight:md-dynamic-height', 'themeClass'],

    attributeBindings: ['alignTabsAttr:md-align-tabs', 'borderBottomAttr:md-border-bottom', 'styleAttr:style'],

    themeClass: (0, _emberComputed['default'])('theme', function () {
      return 'md-' + this.get('theme') + '-theme';
    }),

    alignTabsAttr: (0, _emberComputed['default'])('alignTabs', function () {
      return (0, _emberString.htmlSafe)(this.get('alignTabs'));
    }),

    borderBottomAttr: (0, _emberComputed['default'])('borderBottom', function () {
      return this.get('borderBottom') ? 'md-border-bottom' : null;
    }),

    styleAttr: (0, _emberComputed['default'])('heightStyle', 'transitionStyle', function () {
      return (0, _emberString.htmlSafe)(this.get('transitionStyle') + ' ' + this.get('heightStyle'));
    }),

    // FIXME this is a workaround, tabs should not have a height and use ember-css-transitions instead
    transitionStyle: 'transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1);',

    heightStyle: (0, _emberComputed['default'])('dynamicHeight', 'height', function () {
      if (this.get('dynamicHeight') && this.get('height')) {
        return 'height: ' + this.get('height') + 'px';
      }
      return '';
    }),

    shouldStretchTabs: (0, _emberComputed['default'])('stretchTabs', 'isMobile', 'shouldPaginate', function () {
      switch (this.get('stretchTabs')) {
        case 'always':
          return true;
        case 'auto':
          return this.get('isMobile') && !this.get('shouldPaginate');
        case 'never':
        default:
          return false;
      }
    }),

    shouldPaginate: (0, _emberComputed['default'])('canvasWidth', 'pagingWidth', function () {
      return this.get('pagingWidth') > this.get('canvasWidth');
    }),

    shouldCenterTabs: (0, _emberComputed['default'])('centerTabs', 'shouldPaginate', function () {
      return this.get('centerTabs') && !this.get('shouldPaginate');
    }),

    canPageBack: _emberComputed['default'].gt('offsetLeft', 0),

    canPageForward: (0, _emberComputed['default'])('offsetLeft', 'lastTab', 'canvasWidth', function () {
      var lastTab = this.get('lastTab');
      if (lastTab) {
        var totalWidth = this.get('offsetLeft') + this.get('canvasWidth') - 64; /* lr paddings */
        return offsetRight(lastTab.$()) > totalWidth;
      }
    }),

    offsetLeft: 0,

    tabs: (0, _emberArrayUtils.A)([]),

    lastTab: _emberComputed['default'].readOnly('tabs.lastObject'),

    wormhole: _emberComputed['default'].readOnly('tabsContentWrapper.elementId'),

    selectedTab: (0, _emberComputed['default'])('selected', 'tabs.[]', function () {
      return this.get('tabs')[this.get('selected')];
    }),

    focusIndex: _emberComputed['default'].reads('selected'), // initial value

    updateOffsetLeft: (0, _emberMetalObserver['default'])('focusIndex', 'selected', 'tabs.[]', 'canvasWidth', 'shouldPaginate', 'shouldCenterTabs', function () {
      if (!this.get('shouldPaginate') || this.get('shouldCenterTabs')) {
        return this.set('offsetLeft', 0);
      }

      var focused = this.get('focusIndex');
      var selected = this.get('selected');
      var index = focused === selected ? selected : focused;

      var selectedTab = this.get('tabs')[index];
      if (!selectedTab || !selectedTab.$()) {
        return;
      }

      var canvasWidth = this.get('canvasWidth');
      var currentOffset = this.get('offsetLeft');
      var selectedTabOffset = selectedTab.$().prop('offsetLeft');
      var selectedTabWidth = selectedTab.$().prop('clientWidth');

      var newOffset = Math.max(currentOffset, selectedTabOffset + selectedTabWidth - canvasWidth + 64 /* lr paddings */);
      newOffset = Math.min(newOffset, selectedTabOffset);

      this.set('offsetLeft', newOffset);
    }),

    recomputeSizes: (0, _emberMetalObserver['default'])('shouldStretchTabs', function () {
      _emberRunloop['default'].scheduleOnce('afterRender', this, function () {
        this.updatePagingSize();
        this.updateSelectedTabSizes();
        this.updateTabsHeight();
      });
    }),

    onSelectedTabDisabled: (0, _emberMetalObserver['default'])('selectedTab.disabled', function () {
      if (this.get('selectedTab.disabled')) {
        var closestValidTab = this.findClosestValidTab();
        if (closestValidTab) {
          this.send('selectTab', closestValidTab);
        }
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var tabsWrapper = this.get('childComponents').find(function (child) {
        return child.get('tagName') === 'md-tabs-wrapper';
      });
      var tabsContentWrapper = this.get('childComponents').find(function (child) {
        return child.get('tagName') === 'md-tabs-content-wrapper';
      });
      var tabs = this.get('childComponents').filterBy('tagName', 'md-tab-item');

      var selected = this.get('selected');
      if (selected === undefined) {
        var enabledTabs = tabs.filter(function (tab) {
          return tab.get('disabled') !== true;
        });
        if (enabledTabs.length > 0) {
          selected = tabs.indexOf(enabledTabs[0]);
        }
      }

      var selectedTab = undefined;
      if (selected >= 0) {
        selectedTab = tabs[selected];
      } else {
        selected = null;
      }

      this.setProperties({
        tabs: (0, _emberArrayUtils.A)(tabs),
        tabsWrapper: tabsWrapper,
        tabsContentWrapper: tabsContentWrapper,
        selected: selected,
        loaded: true,
        lastSelectedIndex: null
      });

      if (selectedTab) {
        selectedTab.one('onRendered', (function () {
          this.updateTabsHeight();
          this.updateSelectedTabSizes();
        }).bind(this));
      }

      _emberRunloop['default'].scheduleOnce('afterRender', this, function () {
        this.updateCanvasSize();
        this.updatePagingSize();
        this.updateIsMobile();
      });

      (0, _jquery['default'])(window).on('resize.' + this.elementId, (function () {
        _emberRunloop['default'].scheduleOnce('afterRender', this, function () {
          this.updateCanvasSize();
          this.updateIsMobile();
        });
      }).bind(this));
    },

    registerChild: function registerChild(child) {
      this._super.apply(this, arguments);
      if (this.get('loaded') && child.get('tagName') === 'md-tab-item') {
        this.get('tabs').pushObject(child);
        _emberRunloop['default'].scheduleOnce('afterRender', this, function () {
          this.updatePagingSize();
        });
        if (this.get('autoSelect')) {
          this.send('selectTab', child);
        }
      }
    },

    unregisterChild: function unregisterChild(child) {
      this._super.apply(this, arguments);
      if (this.get('loaded') && child.get('tagName') === 'md-tab-item') {
        this.get('tabs').removeObject(child);
        _emberRunloop['default'].scheduleOnce('afterRender', this, function () {
          this.updatePagingSize();
          var closestTab = this.findClosestValidTab();
          if (closestTab) {
            this.send('selectTab', closestTab);
          }
        });
      }
    },

    willDestroyElement: function willDestroyElement() {
      (0, _jquery['default'])(window).off('resize.' + this.elementId);
      this._super.apply(this, arguments);
    },

    findClosestValidTab: function findClosestValidTab() {
      var currentIdx = this.get('selected');
      if (currentIdx === -1) {
        return null;
      }

      var tabs = this.get('tabs');
      var maxOffset = Math.max(tabs.length - currentIdx, currentIdx);
      var ensureValid = function ensureValid(tab) {
        return tab && tab.get('disabled') !== true ? tab : undefined;
      };

      for (var i = 0; i <= maxOffset; i++) {
        var tab = ensureValid(tabs[currentIdx + i]) || ensureValid(tabs[currentIdx - i]);
        if (tab) {
          return tab;
        }
      }
    },

    updateTabsHeight: function updateTabsHeight() {
      if (this.get('dynamicHeight')) {
        var $tab = this.get('tabsContentWrapper').$('md-tab-content.md-active');
        var tabsHeight = this.get('tabsWrapper').$().prop('scrollHeight');
        var activeTabHeight = $tab ? $tab.prop('scrollHeight') : null;
        if (tabsHeight && activeTabHeight) {
          this.set('height', tabsHeight + activeTabHeight);
        }
      }
    },

    updateSelectedTabSizes: function updateSelectedTabSizes() {
      var selectedTab = this.get('selectedTab');
      if (selectedTab) {
        this.setProperties({
          selectedTabOffsetLeft: selectedTab.$().prop('offsetLeft'),
          selectedTabWidth: selectedTab.$().prop('clientWidth')
        });
      } else {
        this.setProperties({
          selectedTabOffsetLeft: null,
          selectedTabWidth: null
        });
      }
    },

    updatePagingSize: function updatePagingSize() {
      var pagingWidth = 0;
      if (this.get('shouldStretchTabs')) {
        pagingWidth = this.get('canvasWidth');
      } else {
        var $items = this.get('tabsWrapper').$('md-tab-item');
        if ($items) {
          $items.each(function (_, tab) {
            return pagingWidth += tab.clientWidth;
          });
        }
      }
      this.set('pagingWidth', pagingWidth);
    },

    updateCanvasSize: function updateCanvasSize() {
      this.set('canvasWidth', this.get('tabsWrapper').$('md-tabs-canvas').prop('clientWidth'));
    },

    updateIsMobile: function updateIsMobile() {
      this.set('isMobile', window.matchMedia(this.get('constants.MEDIA.xs')).matches);
    },

    actions: {
      nextPage: function nextPage() {
        var totalWidth = this.get('canvasWidth') + this.get('offsetLeft');
        var lastVisibleTab = this.get('tabs').find(function (tab) {
          return offsetRight(tab.$()) > totalWidth;
        });
        if (lastVisibleTab) {
          this.set('focusIndex', lastVisibleTab.get('index'));
        }
      },

      previousPage: function previousPage() {
        var offsetLeft = this.get('offsetLeft');
        var firstVisibleTab = this.get('tabs').find(function (tab) {
          return offsetRight(tab.$()) >= offsetLeft;
        });
        if (firstVisibleTab) {
          this.set('focusIndex', firstVisibleTab.get('index'));
        }
      },

      selectTab: function selectTab(tab) {
        var newSelected = this.get('tabs').indexOf(tab);

        this.setProperties({
          lastSelectedIndex: this.get('selected'),
          selected: newSelected,
          focusIndex: newSelected
        });

        _emberRunloop['default'].scheduleOnce('afterRender', this, function () {
          this.updateTabsHeight();
          this.updateSelectedTabSizes();
        });
      }
    }
  });
});
/**
 * @module ember-paper-tabs
 * @public
 */