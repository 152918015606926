define('ember-paper-tabs/components/paper-tabs/content-wrapper', ['exports', 'ember-component', 'ember-composability-tools', 'ember-paper-tabs/templates/components/paper-tabs/content-wrapper'], function (exports, _emberComponent, _emberComposabilityTools, _emberPaperTabsTemplatesComponentsPaperTabsContentWrapper) {

  /**
   * @class PaperTabsContentWrapper
   * @extends Component
   * @uses ChildMixin
   * @public
   */
  exports['default'] = _emberComponent['default'].extend(_emberComposabilityTools.ChildMixin, {

    tagName: 'md-tabs-content-wrapper',

    layout: _emberPaperTabsTemplatesComponentsPaperTabsContentWrapper['default']

  });
});
/**
 * @module ember-paper-tabs
 * @public
 */